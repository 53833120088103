import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withLayout } from '../layout/Layout';
import styles from './News.module.scss';

export const News1 = withLayout(
    () => {

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [])

        const navigate = useNavigate();
        const onMain = () => {
            navigate('/');
        }
    
        return (
            <div className={styles.wrapper}>

                <button onClick={onMain} className={styles.mainBtn}>
                    На главную
                </button>

                <div className={styles.title}>
                    Дальневосточная хоровая олимпиада в цифрах: что ждет участников и зрителей
                </div>

                <div className={styles.text}>
                Первая Дальневосточная хоровая олимпиада начнется совсем скоро: уже 25 октября во Владивостоке соберутся хоровые коллективы со всей России, чтобы погрузить дальневосточную столицу в атмосферу хорового искусства.
                <br/><br/>
Пока участники репетируют конкурсные произведения, а камертоны «настраиваются» на прослушивания, мы рассказываем о том, что ждет всех на олимпиаде – с небольшой статистикой и любовью к музыке.
                <br/><br/>
<b>15 регионов России</b>
<br/><br/>
Владивосток объединит жителей девяти часовых поясов и 15 регионов страны – от Ленинградской области до Камчатского края. Одни сядут в поезд вечером, и уже утром будут на месте, другим же придется провести в полете более 9-то часов и пересечь всю страну.
<br/><br/>
Но, как бы кто ни добирался, самое удивительное вот что: в столицу Дальнего Востока всех приведет музыка.
<br/><br/>
<b>80 хоров России</b>
<br/><br/>
Младшие, детские и взрослые хоровые коллективы – на Первой Дальневосточной олимпиаде соберутся музыканты всех возрастов. Все они спешат поделиться народными, духовными и классическими произведениями на лучших музыкальных площадках Дальнего Востока.
<br/><br/>
<b>Более 2000 участников</b>
<br/><br/>
На четыре дня во Владивостоке станет на две тысячи музыкантов больше. Пожалуй, на это время он превратится в настоящую музыкальную столицу России. А еще не исключено, что хористы ненадолго перепоют шум моря.
<br/><br/>
<b>4 крупных концерта</b>
<br/><br/>
С 25 по 29 октября на нескольких сценах Владивостока можно будет услышать совершенно разные произведения: от народных песен в современной обработке до духовных композиций под куполом лютеранской церкви.
<br/><br/>
Слушатели смогут выбрать музыку по душе, а смогут и наоборот – сходить на несколько концертов и ощутить, насколько многообразно хоровое искусство.
<br/><br/>
<b>10 номинаций</b>
<br/><br/>
Чтобы хоровые коллективы раскрылись полностью, для них предусмотрено участие в нескольких номинациях. Так они покажут, что хор – это не только про классику или духовность. Хор – это про то, как любую эмоцию донести через музыку.
<br/><br/>
Ждем вас на Дальневосточной хоровой олимпиаде!
                </div>
            </div>
        )
    }
)